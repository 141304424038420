import React from "react";
import Signupcard from "../layout/Signupcard";
import Navbar from "../layout/Navbar";
export default function Login() {
  return (
    <div>
      <Navbar home getStarted tools />
      <Signupcard />
    </div>
  );
}
